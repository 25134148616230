import { ComponentStylingOptions, ReusableComponentProps } from '@packfleet/ui';
import cs from 'classnames';
import React, { FunctionComponent, RefObject, useEffect, useRef } from 'react';

type Props = ReusableComponentProps & {
  inputRef?: RefObject<HTMLInputElement>;
  autoFocus?: boolean;
  s?: 'small' | 'regular';
} & React.HTMLProps<HTMLInputElement>;

const Input: FunctionComponent<Props> = ({
  inputRef,
  className,
  style,
  unstyled,
  autoFocus = false,
  s = 'regular',
  ...props
}) => {
  const options: ComponentStylingOptions = {
    classNames: className,
    style: style,
  };

  const ref = useRef(null);
  if (!inputRef) {
    inputRef = ref;
  }
  useEffect(() => {
    if (autoFocus) {
      inputRef?.current?.focus();
    }
  }, [autoFocus, inputRef]);

  if (!unstyled) {
    options.classNames = cs(
      options.classNames,
      'px-3 rounded border border-primary',
      { 'h-12': s === 'regular', 'h-10': s === 'small' },
    );
  }

  return (
    <input
      ref={inputRef}
      className={options.classNames}
      style={options.style}
      {...props}
    />
  );
};

export default Input;
