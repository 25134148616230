import React, { ReactNode, FunctionComponent, useEffect, useRef } from 'react';

export type Props = {
  action: () => unknown;
  children?: ReactNode;
};

const OnClickOutside: FunctionComponent<Props> = ({ children, action }) => {
  const ref = useRef<HTMLElement>(null);
  useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      if (!ref.current) return;
      if (ref.current?.contains(e.target as Node)) {
        return;
      }
      action();
    };

    document.addEventListener('mousedown', onClickOutside);
    return () => document.removeEventListener('mousedown', onClickOutside);
  }, [children]);
  const child = React.Children.only(children);
  return React.cloneElement(child as React.ReactElement, {
    ref: ref,
  });
};

export default OnClickOutside;
