import cs from 'classnames';
import { useFormikContext } from 'formik';
import { ReactNode, useEffect } from 'react';

export type Props = {
  className?: string;
  size?: 'small' | 'regular';
  id: string;
  children?: ReactNode;
};

const FormError = ({ className, id, size = 'small', children }: Props) => {
  const sizeClass = {
    small: 'text-xs',
    regular: '',
  }[size];

  const formikContext = useFormikContext();

  useEffect(() => {
    if (formikContext?.isValid) return;

    const element = document.querySelector('[data-form-error]');
    if (!element) return;

    element.scrollIntoView({
      block: 'center',
    });
  }, [formikContext?.submitCount, formikContext?.isValid]);

  return (
    <div
      className={cs('text-danger', sizeClass, className)}
      id={id}
      data-form-error={id}
    >
      {children}
    </div>
  );
};

export default FormError;
