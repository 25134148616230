import React, { ReactNode, useState } from 'react';
import Alert from './Alert';

export type Props = {
  button: React.ReactElement;
  title: string;
  body?: ReactNode;
  confirmText: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  onOpen?: () => Promise<void>;
  loading?: boolean;
};

export const WithAlert = ({ button, onOpen, ...props }: Props) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <>
      {React.cloneElement(button, {
        onClick: async () => {
          if (onOpen) {
            setLoading(true);
            try {
              await onOpen();
            } finally {
              setLoading(false);
            }
          }
          setShow(true);
        },
        loading,
      })}
      <Alert
        {...props}
        show={show}
        onConfirm={async () => {
          await props.onConfirm();
          setShow(false);
        }}
        onCancel={() => {
          setShow(false);
          if (props.onCancel) props.onCancel();
        }}
      />
    </>
  );
};

export default WithAlert;
