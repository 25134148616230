/* eslint-disable @typescript-eslint/ban-types */

import { useRouter } from 'next/router';
import React from 'react';
import { useCurrentUserQuery } from '../generated/graphql';
import { Routes, route } from './routes';

// redirectIfNotLoggedIn checks if the user can be fetched. If not, and an unauthorized
// error is thrown, it redirects to the login page. It's kind of a hack
// around next.js's limitations on redirecting from the getServerSideProps function.
export function useRedirectIfNotLoggedIn(allowNoOrganization?: boolean): {
  redirected?: boolean;
  firstLoad?: boolean;
} {
  const { data, loading, error } = useCurrentUserQuery();
  const router = useRouter();

  let isLoggedIn = false;
  if (allowNoOrganization) {
    isLoggedIn = data?.currentUser?.user != null;
  } else {
    isLoggedIn = data?.currentUser?.user?.organization != null;
  }

  if (isLoggedIn) {
    return { redirected: false };
  } else if (loading) {
    return { firstLoad: true };
  } else if (!error) {
    // If we're doing SSR and there's no user, assume we'll redirect
    // client-side as the router won't work on the server.
    if (typeof window !== 'undefined') {
      if (data?.currentUser?.user && !data?.currentUser?.user.organization) {
        void router.replace(
          route(Routes.signUp, { redirect_uri: router.asPath }),
        );
      } else {
        void router.replace(
          route(Routes.login, { redirect_uri: router.asPath }),
        );
      }
    }
    return { redirected: true };
  }

  return { redirected: false };
}

export function requireAuthentication<T>(
  // biome-ignore lint/complexity/noBannedTypes: Hangover from ESLint migration
  Cmp: React.ComponentType<T> & { getInitialProps?: Function },
  opts?: { allowNoOrganization?: boolean },
) {
  const Wrapped = (props: T) => {
    const { redirected, firstLoad } = useRedirectIfNotLoggedIn(
      opts?.allowNoOrganization,
    );
    if (redirected || firstLoad) {
      return null;
    }
    return <Cmp {...props} />;
  };
  Wrapped.displayName = `requireAuthentication(${Cmp.displayName || Cmp.name})`;
  Wrapped.getInitialProps = Cmp.getInitialProps;
  return Wrapped;
}

// redirectIfLoggedIn checks if the user can be fetched. If not, and an unauthorized
// error is thrown, it allows the page to load, else it redirects to dashboard.
// It's kind of a hack
// around next.js's limitations on redirecting from the getServerSideProps function.
function useRedirectIfLoggedIn(requireOrganization?: boolean): {
  redirected?: boolean;
  firstLoad?: boolean;
} {
  const { data, loading, error } = useCurrentUserQuery();
  const router = useRouter();

  let isLoggedIn = false;
  if (requireOrganization) {
    isLoggedIn = data?.currentUser?.user?.organization != null;
  } else {
    isLoggedIn = data?.currentUser?.user != null;
  }

  if (isLoggedIn) {
    // If we're doing SSR and there's a user, assume we'll redirect
    // client-side as the router won't work on the server.
    if (typeof window !== 'undefined') {
      void router.replace(route(Routes.appDashboard));
    }
    return { redirected: true };
  } else if (loading) {
    return { firstLoad: true };
  } else if (!error) {
    return { redirected: false };
  }

  return { redirected: false };
}

export function requireUnauthenticated<T>(
  // biome-ignore lint/complexity/noBannedTypes: Hangover from ESLint migration
  Cmp: React.ComponentType<T> & { getInitialProps?: Function },
  opts?: { requireOrganization?: boolean },
) {
  const Wrapped = (props: T) => {
    const { redirected } = useRedirectIfLoggedIn(opts?.requireOrganization);
    if (redirected) {
      return null;
    }
    return <Cmp {...props} />;
  };
  Wrapped.displayName = `requireUnauthenticated(${
    Cmp.displayName || Cmp.name
  })`;
  Wrapped.getInitialProps = Cmp.getInitialProps;
  return Wrapped;
}
