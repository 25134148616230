import { Temporal } from '@js-temporal/polyfill';
import { format } from 'date-fns';
import { Maybe } from '../generated/graphql';

export type TimeType = {
  hour: number;
  minute: number;
  totalMinutes: number;
  valid: boolean;
};

export type DateType = {
  date?: Temporal.PlainDate;
  valid: boolean;
};

export function marshalTime(time: string | null | undefined) {
  if (!time) return time;

  if (/^[0-9]{1,2}[0-9]{2}$/.test(time)) {
    // Convert 0900 into 09:00
    time = time.replace(/([0-9]{2})$/, ':$1');
  }

  try {
    return format(new Date(`1/1/1 ${time}`), 'HH:mm');
  } catch (e) {
    return time;
  }
}

export function parseTime(val?: string | null): TimeType {
  // Ensure 24h format
  val = marshalTime(val);

  if (!val) {
    return { hour: 0, minute: 0, totalMinutes: 0, valid: false };
  }

  const result = val.split(':');
  if (result.length !== 2) {
    return { hour: 0, minute: 0, totalMinutes: 0, valid: false };
  }
  if (!result.every((part) => part.length === 2)) {
    return { hour: 0, minute: 0, totalMinutes: 0, valid: false };
  }
  const hour = parseInt(result[0]);
  if (isNaN(hour)) {
    return { hour: 0, minute: 0, totalMinutes: 0, valid: false };
  }
  const minute = parseInt(result[1]);
  if (isNaN(minute)) {
    return { hour: 0, minute: 0, totalMinutes: 0, valid: false };
  }

  if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
    return { hour: 0, minute: 0, totalMinutes: 0, valid: false };
  }

  return { hour, minute, totalMinutes: hour * 60 + minute, valid: true };
}

export function includesTime(str: string) {
  return (
    // HH:MM, optional leading zero
    /([0-1]?[0-9]|2[0-3])[:.][0-5][0-9]/.test(str) ||
    // HH am/pm
    /[0-9][ ]?([AaPp][Mm])/.test(str) ||
    // before HH / after HH / between HH and HH
    /(before|after|between) [0-1]?[0-9]/.test(str)
  );
}

export function formatStartEndTime(start?: Maybe<string>, end?: Maybe<string>) {
  if (start && end) {
    return `${start} to ${end}`;
  } else if (start) {
    return `${start} onwards`;
  } else if (end) {
    return `Before ${end}`;
  }
  return '';
}

export const formatTimeMinutes = (timeMinutes: number) => {
  const hours = Math.floor(timeMinutes / 60);
  const minutes = ((timeMinutes / 60) % 1) * 60;

  const d = new Date();
  d.setHours(hours);
  d.setMinutes(minutes);

  return format(d, 'HH:mm');
};

export const getNextHalfHourMinutes = () => {
  const now = new Date();

  // Round to next half hour increment
  return Math.ceil((now.getHours() * 60 + now.getMinutes()) / 30) * 30;
};

export function formatTimeRange(
  startTime?: string | null,
  endTime?: string | null,
  abbreviate?: boolean,
) {
  return (
    [
      startTime && endTime
        ? abbreviate
          ? ''
          : 'Between '
        : endTime
          ? 'Before '
          : startTime
            ? 'After '
            : '',
      startTime,
      startTime && endTime ? (abbreviate ? ' to ' : ' and ') : null,
      endTime,
    ].join('') || 'Any time'
  );
}
