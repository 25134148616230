import cs from 'classnames';
import React, {
  FunctionComponent,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export type Props = {
  label: ReactNode;
  description?: ReactNode;
  name: string;
  onChange: (checked: boolean) => void;
  checked?: boolean;
  labelClassName?: string;
};

export const Toggle: FunctionComponent<Props> = ({
  name,
  label,
  description,
  labelClassName = 'items-center',
  checked: _checked,
  onChange: _onChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(_checked ?? false);
  useEffect(() => {
    if (_checked != null) {
      setChecked(_checked);
    }
  }, [_checked]);
  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const c = e.currentTarget.checked;
      setChecked(c);
      _onChange(c);
    },
    [_onChange],
  );

  const handleClickCheckbox = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.preventDefault();
      inputRef.current?.click();
    },
    [],
  );

  return (
    <>
      <label className={cs('flex', labelClassName)}>
        <button
          className={cs('relative flex-shrink-0 rounded-full', {
            'bg-success': checked,
            'bg-faded': !checked,
          })}
          aria-describedby={`${name}-toggle-description`}
          style={{
            width: 42,
            height: 24,
            transition: 'background-color 300ms ease',
          }}
          onClick={handleClickCheckbox}
        >
          <span
            className="absolute rounded-full bg-primary"
            style={{
              width: 18,
              height: 18,
              top: 3,
              left: 3,
              transform: `translate3d(${checked ? '18px' : '0'}, 0, 0)`,
              transition: 'transform 200ms ease',
            }}
          />
        </button>
        <input
          ref={inputRef}
          type="checkbox"
          className="hidden"
          name={name}
          checked={checked}
          onChange={onChange}
        />
        <div className="ml-4" />
        <span>{label}</span>
      </label>
      {description ? (
        <div
          className="mt-2 text-sm text-secondary"
          id={`${name}-toggle-description`}
        >
          {description}
        </div>
      ) : null}
    </>
  );
};

export default Toggle;
