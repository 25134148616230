import { Button } from '@packfleet/ui';
import cs from 'classnames';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import Heading from '../heading/Heading';
import Modal from './Modal';

export type Props = {
  show: boolean;
  title: string;
  body?: React.ReactNode;
  confirmText: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  align?: 'center' | 'left';
  loading?: boolean;
};

export const Alert: FunctionComponent<Props> = ({
  show,
  title,
  body,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  align = 'left',
  loading,
}) => {
  const mainRef = useRef<HTMLElement | undefined>(undefined);
  useEffect(() => {
    mainRef.current = document.getElementsByTagName('main')[0];
  });

  return (
    <Modal
      isOpen={show}
      onClose={onCancel ? onCancel : onConfirm}
      appElement={mainRef.current}
    >
      <div
        className={cs(
          'min-w-min relative w-full max-w-md rounded border border-primary bg-primary p-8',
          {
            'text-center': align === 'center',
          },
        )}
      >
        <Heading level={2} className="text-primary">
          {title}
        </Heading>
        {body && typeof body === 'string' ? (
          <p className="text-secondary">{body}</p>
        ) : (
          body || null
        )}
        <div
          className={cs('mt-8 flex', {
            'justify-end': align === 'left',
            'justify-center': align === 'center',
          })}
        >
          {cancelText ? (
            <Button
              onClick={onCancel}
              mode="outline"
              color="neutral"
              className="mr-2"
            >
              {cancelText}
            </Button>
          ) : null}
          <Button onClick={onConfirm} loading={loading}>
            {confirmText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Alert;
